import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

const ResearchersList = () => {

  const data = useStaticQuery
  (graphql`
query ResearchersQuery {
  researchers:  allAirtable (
	 filter: { table: { eq: "Researchers" } }
   ) {
	 edges {
	   node {
		 recordId
		 data {
		   FullName
		   First
		 }
	   }
	 }
   }
 }
  `);
};
  
export default ResearchersList;