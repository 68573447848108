import React, { useState } from "react";
import { Link } from "gatsby"
import Layout from "../components/layout/Layout"
import Container from "../components/layout/Container"
import Type from "../components/typography/Type"
import { isLoggedIn } from "../services/auth"
import axios from "axios";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import LoginPrompt from "../components/app/loginPrompt"
import Input from "../components/forms/Input"
import Select from "../components/forms/Select"
import ResearcherDropdown from "../components/data/ResearcherDropdown"
import TextArea from "../components/forms/TextArea"
import Hidden from "../components/forms/Hidden"


 const formSchema = Yup.object().shape({
  ProjectName: Yup.string().required("Required"),
  NumSessions: Yup.number()
  .required("Required")
  .typeError("The value must be a number"),
  LeadResearcher: Yup.string().required("Required"),
  CaptureClientContact: Yup.string().required("Required"),
  CaptureClientEmail: Yup.string()
  .email("Not a valid email")
  .required("Required"),
  CaptureClientOrg: Yup.string().required("Required"),
  ProjectBrief: Yup.string().required("Required"),
  ParticipantCriteria: Yup.string().required("Required"),
  DovetailURL: Yup.string().required("Required"),
  ResearchObjectives: Yup.string().required("Required"),
  ProjectURL: Yup.string().required("Required"),
  AskableURL: Yup.string().required("Required"),  
});


const NewProject = () => {
  
  
 // Axios server state handling
  const [serverState, setServerState] = useState();
  const handleServerResponse = (ok, msg) => {
    setServerState({ok, msg});
  };
  const handleOnSubmit = (values, actions) => {
    axios({
      method: "POST",
      url: "https://formspree.io/mzbqzkdk",
      data: values,
    })
      .then(response => {
        actions.setSubmitting(false);
        actions.resetForm();
        handleServerResponse(true, "Thanks!");
      })
      .catch(error => {
        actions.setSubmitting(false);
        handleServerResponse(false, error.response.data.error);
      });
  };
  
  
  

  
  return (
    <Layout>
    <Container>
    
    
    {isLoggedIn() ? (
      <div>
        <Type type="h2">Add a new project</Type>
        <Formik
          initialValues={{
            DovetailURL: "http://",
            ProjectURL: "http://",
            AskableURL: "http://",
            Segments: "Separate multiple segments with a comma",
            Protocols: "Remote Discovery",
            Status: "Design",
            ResearchObjectives: "Separate multiple objectives with a comma"
          }}
          onSubmit={handleOnSubmit}
          validationSchema={formSchema}
        >
          {({ values,
             errors,
             touched,
             handleChange,
             handleBlur,
             handleSubmit,
             isSubmitting 
           }) => (
            
            <Form id="newproject" noValidate >
              
              <Field id="Status" name="Status" fieldLabel="Status" component={Hidden} />
              <Field id="Protocols" name="Protocols" fieldLabel="Protocols" component={Hidden} />
                            
     
              <div className="flex flex-col space-y-12 w-2/3">

                <Field id="ProjectName" name="ProjectName" fieldLabel="Project Name" component={Input} />
                <Type type="h5" nopadding="yes">Client</Type>
                <div className="grid grid-cols-2 gap-8">
                  <Field id="CaptureClientContact" name="CaptureClientContact" fieldLabel="Client Contact Name" component={Input} />
                  <Field id="CaptureClientEmail" name="CaptureClientEmail" fieldLabel="Client Email" component={Input} />
                  <Field id="CaptureClientOrg" name="CaptureClientOrg" fieldLabel="Client Organisation" component={Input} />
                </div>
                <Type type="h5" nopadding="yes">Requirements</Type>
                <div className="grid grid-cols-2 gap-8">
                <Field id="NumSessions"  name="NumSessions" fieldLabel="Number of Sessions" component={Input} />
                <Field as="select" id="LeadResearcher" name="LeadResearcher" fieldLabel="Lead Researcher" component={Select}>
                    <option/>
                   <ResearcherDropdown/>
                 </Field>
                </div>
                <Type type="h5" nopadding="yes">Resquirements</Type>
                <Field id="ProjectBrief"  name="ProjectBrief" fieldLabel="Project Brief" component={TextArea} />
                <Field id="ResearchObjectives"  name="ResearchObjectives" fieldLabel="Research Objectives" component={TextArea} />
                 <Field id="ParticipantCriteria"  name="ParticipantCriteria" fieldLabel="Participant Criteria" component={TextArea} />
                 <Field id="Segments"  name="Segments" fieldLabel="Segments" component={TextArea} />

                <Type type="h5" nopadding="yes">Project Links</Type>
                <div className="grid grid-cols-2 gap-8">
                  <Field id="ProjectURL"  name="ProjectURL" fieldLabel="Project Folder URL" component={Input} />
                  <Field id="DovetailURL"  name="DovetailURL" fieldLabel="Dovetail URL" component={Input} />
                  <Field id="AskableURL"  name="AskableURL" fieldLabel="Askable URL" component={Input} />
                </div>
   




                
                  {/*}
                <FieldArray 
                  name="ResearchObjectives"
                  render={arrayHelpers => (
                    <div className="flex flex-col space-y-12">
                      {values.ResearchObjectives && values.ResearchObjectives.length > 0 ? ( 
                       values.ResearchObjectives.map((objective, index) => (
                         <div key={index} className="flex flex-row items-center space-x-12">
                            <div className="grow">
                               <Field id={`ResearchObjectives[${index}]`}  fieldLabel={`Objective ${index+1}`}  name={`ResearchObjectives[${index}]`} component={Input}/>
                            </div>
                               <button
                                 className="w-8 h-8 bg-green/25 flex items-center justify-center rounded-lg text-white text-lg"
                                 type="button"
                                 onClick={() => arrayHelpers.remove(index)} // remove a goal from the list
                                 >x</button>
                    
                        </div>
                        )) 
                        
                      ) :
                
                      (
                        
                       
                        undefined
                      )}       
                      <button 
                      className=" w-fit bg-green/25 py-2 px-4 rounded-lg text-white"
                      type="button" onClick={() => arrayHelpers.push('')}>
                      
                         Add an objective
                       </button> 
                    </div>  
                )}
                /> 
        */}



              </div>
              
              <button type="submit" className="mt-16 bg-green rounded-lg py-4 px-8 text-white border text-lg border-green hover:bg-white hover:text-green font-bold" disabled={isSubmitting}>
                Submit
              </button>
              
              <div>
             {serverState && (
               <div className="mt-8">
               <div className={!serverState.ok ? 
                 
                 
                 "errorMsg"
                 
                 : 
                 
                 ""
               
               }>
                 {serverState.msg}
               </div>
               </div>
             )}
              </div>
            </Form>
          )}
        </Formik>
      </div> 
   ) : (
       <div>
         <LoginPrompt/>
       </div>     
     )}



   </Container>
    </Layout>
  )
}

export default NewProject

export const Head = () => <title>Sessions</title>

