import * as React from "react";

const Hidden = ({ 
  field, 
}) => {



const fieldName = field.name
return (
    <input
      type="hidden"
      {...field}
    />
   
 );
};  
export default Hidden ;