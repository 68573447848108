import * as React from "react";
import Type from "../../components/typography/Type"



const Input = ({ 
  field, 
  form: { touched, errors, values },
  fieldLabel,
}) => {



const fieldName = field.name
return (
    <div className="w-full flex flex-col space-y-4">
    <label htmlFor={fieldName}>
      <Type type="p6" nopadding="yes">{fieldLabel}</Type>
    </label>
    <input
      type="text"
      className="w-full p-4 rounded-lg border border-grey/25 focus:border-green focus:outline-2 focus:ring-green/25"
      {...field}
    />
    {errors[fieldName] && touched[fieldName] ? (<div className="mt-4 text-base font-light bg-red/10 py-2 px-4 rounded-lg w-fit">{errors[fieldName]}</div>) : null}
  </div>	
   
 );
};  
export default Input ;